<template>
    <basic-page class="governance" :pagedata="this.pageData" bannerimg="/img/governance-banner.jpg">
        <div class="container-beige intro container">
            <h3 class="occhiello">
                Il Polo del Gusto
            </h3>
            <h2>
                {{ this.lang=='it' ? 'Esperienza, Competenza, Passione' : 'Experience, Competence, Passion'}}
            </h2>
        </div>
        <div class="container people" v-if="mainPeople.length > 0">
            <img src="../assets/filigrana-2.png" v-parallax="0.3" class="filigrana filigrana-2" alt="">
            <img src="../assets/filigrana-1.png" v-parallax="0.3" class="filigrana" alt="">
            <div v-for="(p, index) in mainPeople" :key="'person'+index"
                :class="{'person': true, 'person-2': index==1 || index==3}">
                <div class="img" data-aos="reveal-image">
                    <img v-if="p && p.field_person_photo"
                        :src="sourceProfilePhoto(p.field_person_photo.attributes.uri.url)" width="300" height="425"
                        alt="">
                </div>
                <div v-if="p && p.attributes">
                    <p class="role">{{ p.attributes.field_person_position }}</p>
                    <h2>{{ p.attributes.field_name }} <br>{{ p.attributes.field_last_name }}</h2>
                    <p :class="['bio',{ 'open': isOpen.includes(index) }]"
                        v-if="p.attributes.field_description_short_bio"
                        v-html="p.attributes.field_description_short_bio.processed"></p>
                    <a @click="openAccordion(index)">{{accordinText(index)}}</a>
                </div>
            </div>

        </div>
        <div class="container people-consiglieri" v-if="consiglieri.length > 0">
            <div class="person" v-for="(c, index) in consiglieri" :key="'consigliere'+index">
                <div class="img">
                    <img v-if="c && c.field_person_photo"
                        :src="sourceProfilePhoto(c.field_person_photo.attributes.uri.url)" width="223" height="202"
                        alt="">
                </div>
                <div v-if="c && c.attributes">
                    <p class="role">
                        {{ c.attributes.field_person_position }}
                    </p>
                    <h2>
                        {{ c.attributes.field_name }} <br>{{ c.attributes.field_last_name }}
                    </h2>
                </div>
            </div>


        </div>

        <!-- OLD SECTION -->
        <!-- <div class="governance-bottom-text" v-if="this.lang == 'it'">
            <div class="container">
                <div>
                    <h3>
                        Collegio Sindacale
                    </h3>
                     <p>
                        <ul>
                            <li>
                                <span>
                                    Presidente Collegio Sindacale
                                </span>
                                Francesco Spadaro
                            </li>
                            <li>
                                <span>
                                    Sindaco Effettivo
                                </span>
                                Flavia Minutillo
                            </li>
                            <li>
                                <span>
                                    Sindaco Effettivo
                                </span>
                                Antonio Morello 
                            </li>
                            <li>
                                <span>
                                    Sindaco Supplente
                                </span>
                                Alberto Cappell
                            </li>
                            <li>
                                <span>
                                    Sindaco Supplente
                                </span>
                                Del Piero Michela
                            </li>
                        </ul>
                    </p>
                    <p>Il Collegio Sindacale della società vigila sull'osservanza della legge e dello statuto sociale e
                        possiede funzioni di controllo sulla gestione. </p>
                   
                </div>
                <div>
                    <h3>
                        Società di revisione
                    </h3>
                    <p>
                        <ul>
                            <li>
                                <span>
                                    Revisore
                                </span>
                                Reconta Ernst & Young
                            </li>
                        </ul>
                    </p>
                    <p>
                        La società di revisione, iscritta ad apposito registro, è l’organo di controllo esterno a cui è
                        demandata la revisione legale dei conti.
                    </p>
                    
                </div>
                <div>
                    <h3>
                        Organismo di Vigilanza
                    </h3>
                     <p>
                        <ul>
                            <li>
                                <span>Presidente</span>
                                Francesco Spadaro 
                            </li>
                            <li>
                                <span>Membro</span>
                                Flavia Minutillo
                            </li>
                            <li>
                                <span>Membro</span>
                                Antonio Morello
                            </li>
                        </ul>
                    </p>
                    <p>
                        L’Organismo di Vigilanza della società ha il compito di vigilare costantemente sull’osservanza
                        del Modello Organizzativo emanato al fine di monitorarne la sua efficacia nel prevenire la
                        commissione di Reati attraverso il rispetto delle prescrizioni nello stesso contenute. Inoltre,
                        deve procedere al suo aggiornamento qualora se ne riscontri la necessità a causa di cambiamenti
                        sopravvenuti alla struttura, all’organizzazione aziendale o al quadro normativo di riferimento.
                    </p>
                   
                    <p class="bold">
                        Servizio segnalazioni “whistleblowing”: <a
                            href="mailto:odv@polodelgusto.com">odv@polodelgusto.com</a>
                    </p>
                </div>
                <div>
                    <h3>
                        Modello di Organizzazione e Gestione
                    </h3>
                    <p>
                        La società possiede un Modello di Organizzazione e Gestione atto a definire le norme di
                        comportamento e individuarne i presidi di controllo al fine di prevenire il rischio di
                        commissione di reati previsti dal Decreto Legislativo n. 231 del 2001 ed escludere o limitare le
                        conseguenti responsabilità amministrative in capo alla Società.
                    </p>
                    <p class="downloads">
                        <a  href="/download/PdG_codice_etico_CdA 25feb22.pdf" download>Scarica Codice etico
                            <span>
                                <span><img src="../assets/icon-download.svg" alt="download"></span>
                            </span>
                        </a>
                        <a  href="/download/PdG_modello231_CdA 25feb22.pdf" download>Scarica Modello di organizzazione e gestione
                            <span><img src="../assets/icon-download.svg" alt="download"></span>
                        </a>
                    </p>
                </div>

                
            </div>
        </div> -->

        <!-- <div class="data-cessazione">
            <p> <i> Data cessazione: approvazione bilancio 31/12/2021 </i> </p>
        </div> -->

        <!-- COLLEGIO SINDACALE -->
        <div class="governance-bottom-text">
            <div class="container">
                <div>
                    <h3>
                        {{ this.lang=='it' ? 'Collegio Sindacale' : 'Board of Statutory Auditors' }}
                    </h3>

                    <p>
                        <ul v-if="this.lang=='it'">
                            <li v-for="(n, ind) in collegioSindacaleIT" :key="'coll'+ind">
                                <span> {{ n.title }} </span>
                                {{ n.name }}
                            </li>
                        </ul>
                        <ul v-else>
                            <li v-for="(n, ind) in collegioSindacaleEN" :key="'coll'+ind">
                                <span> {{ n.title }} </span>
                                {{ n.name }}
                            </li>
                        </ul>
                    </p>

                    <p v-if="this.lang=='it'">
                        Il Collegio Sindacale della società vigila sull'osservanza della legge e dello statuto sociale e possiede 
                        funzioni di controllo sulla gestione. 
                    </p>
                    <p v-else>
                        The company's Board of Statutory Auditors oversees the compliance with the law and with the company by-laws 
                        and provides control functions upon the management.
                    </p>
                </div>

                <!-- SOCITETA' DI REVISIONE -->

                <div v-if="this.lang=='it'">
                    <h3> Società di revisione </h3>
                    <p> <ul>
                        <li>
                            <span> Revisore </span>
                            KPMG S.p.A
                        </li>
                    </ul> </p>
                    <p>
                        La società di revisione, iscritta ad apposito registro, è l’organo di controllo esterno a cui è
                        demandata la revisione legale dei conti.
                    </p>
                </div>
                <div v-else>
                    <h3> Independent Auditors </h3>
                    <p> <ul>
                        <li>
                            KPMG S.p.A
                        </li>
                    </ul> </p>
                    <p>
                        The independent auditors are registered with a special Register of Independent Auditors and represent 
                        the external control body in charge of the control of the financial statements.
                    </p>
                </div>

                <!-- ORGANISMO DI VIGILANZA -->

                <div>
                    <h3> {{ this.lang=='it' ? 'Organismo di Vigilanza' : 'Supervisory Body' }} </h3>
                    <p>
                        <ul v-if="this.lang=='it'">
                            <li v-for="(m, ind) in organismoVigilanzaIT" :key="'vig'+ind">
                                <span> {{ m.title }} </span>
                                {{ m.name }}
                            </li>
                        </ul>
                        <ul v-else>
                            <li v-for="(m, ind) in organismoVigilanzaEN" :key="'vig'+ind">
                                <span> {{ m.title }} </span>
                                {{ m.name }}
                            </li>
                        </ul>
                    </p>

                    <p v-if="this.lang=='it'">
                        L’Organismo di Vigilanza della società ha il compito di vigilare costantemente sull’osservanza
                        del Modello Organizzativo emanato al fine di monitorarne la sua efficacia nel prevenire la
                        commissione di Reati attraverso il rispetto delle prescrizioni nello stesso contenute. Inoltre,
                        deve procedere al suo aggiornamento qualora se ne riscontri la necessità a causa di cambiamenti
                        sopravvenuti alla struttura, all’organizzazione aziendale o al quadro normativo di riferimento.
                    </p>
                    <p v-else>
                        The Company's Supervisory Body constantly provides a screening compliance with the Organizational 
                        Model adopted by the company in order to oversees its effectiveness in preventing the violation of 
                        the law through the compliance with the provisions set forth therein. In addition, the Supervisory 
                        Body must update the Organizational Model, should circumstances require it, due to changes occurring 
                        within the structure, Company's organization or regulatory framework of reference.
                    </p>
                   
                    <p v-if="this.lang=='it'" class="bold"> 
                        Servizio segnalazioni “whistleblowing”: 
                        <a href="https://polodelgusto.integrityline.com/" target="_blank">integrityline</a>	
                    </p>
                    <p v-else class="bold"> 
                        Whistleblowing Procedure/Policy:
                        <a href="https://polodelgusto.integrityline.com/" target="_blank">integrityline</a>	
                    </p>
                </div>

                <!-- MODELLO DI ORGANIZZAZIONE -->

                <div v-if="this.lang=='it'">
                    <h3> Modello di Organizzazione e Gestione </h3>
                    <p>
                        La società possiede un Modello di Organizzazione e Gestione atto a definire le norme di
                        comportamento e individuarne i presidi di controllo al fine di prevenire il rischio di
                        commissione di reati previsti dal Decreto Legislativo n. 231 del 2001 ed escludere o limitare le
                        conseguenti responsabilità amministrative in capo alla Società.
                    </p>
                    <p class="downloads">
                        <a href="./codice-etico-rev-2023.pdf" download>
                            Scarica Codice etico
                            <span><img src="../assets/icon-download.svg" alt="download"></span>
                        </a>
                        <a href="./modello-organizzazione-e-gestione-rev-2023.pdf" download>
                            Scarica Modello di organizzazione e gestione
                            <span><img src="../assets/icon-download.svg" alt="download"></span>
                        </a>
                    </p>
                </div>
                <div v-else>
                    <h3> Organization and Management Policy </h3>
                    <p>
                        The Company has drawn up an Organization and Management Policy that defines the standards of ethical 
                        conducts in order to prevent the risk of the violation of law as set out by the Legislative Decree n. 
                        231 of 2001 and to waive or to limit company’s liabilities.
                    </p>
                    <p class="downloads"> 
                        <a href="/public/download/PdG_codice_etico_CdA 25feb22.pdf" download>
                            Download code of ethics (IT)
                            <span><img src="../assets/icon-download.svg" alt="download"></span>
                        </a>
                        <a href="/public/download/modello-organizzazione-e-gestione-rev-2023.pdf" download>
                            Download organization and management policy (IT)
                            <span><img src="../assets/icon-download.svg" alt="download"></span>
                        </a>
                    </p>
                </div>
            </div>
        </div>
        <div class="full-image">
            <img v-parallax="0.3" src="../assets/governance-banner.jpg" alt="">
            <h2 v-html="this.pageData.field_page_ui_testo[0].attributes.field_ui_testo[0].processed">
            </h2>
        </div>
    </basic-page>
</template>

<script>
    import BasicPage from './BasicPage.vue'
    import {
        fetchSinglePage,
        fetchNodes,
        getTranslation
    } from '../libs/drupalClient'
    export default {
        components: {
            BasicPage
        },
        name: 'governance',
        data: () => {
            return {
                currentPageId: "d499ebb1-676d-4bf8-b580-7582a8c3cf1c",
                pageData: {
                    attributes: {}
                },
                personData: [],
                p1: 0,
                p2: 0,
                mouseX: 0,
                mouseY: 0,
                isOpen: [],
                collegioSindacaleIT: [
                    {
                        title: "Presidente",
                        name: "Maurizio Ferrero"
                    },
                    {
                        title: "Sindaco Effettivo",
                        name: "Laura Ilaria Neri"
                    },
                    {
                        title: "Sindaco Effettivo",
                        name: "Antonio Morello"
                    },
                    {
                        title: "Sindaco Supplente",
                        name: "Michela Del Piero"
                    },
                    {
                        title: "Sindaco Supplente",
                        name: "Alberto Cappel"
                    }
                ],
                collegioSindacaleEN: [
                    {
                        title: "Chairman",
                        name: "Maurizio Ferrero"
                    },
                    {
                        title: "Auditor",
                        name: "Laura Ilaria Neri"
                    },
                    {
                        title: "Auditor",
                        name: "Antonio Morello"
                    },
                    {
                        title: "Deputy Auditor",
                        name: "Michela Del Piero"
                    },
                    {
                        title: "Deputy Auditor",
                        name: "Alberto Cappel"
                    }
                ],
                organismoVigilanzaIT: [
                    {
                        title: "Presidente",
                        name: "Andrea Milani"
                    },
                    {
                        title: "Membro",
                        name: "Laura Ilaria Neri"
                    },
                    {
                        title: "Membro",
                        name: "Antonio Morello"
                    }
                ],
                organismoVigilanzaEN: [
                    {
                        title: "Chairman",
                        name: "Andrea Milani"
                    },
                    {
                        title: "Member",
                        name: "Laura Ilaria Neri"
                    },
                    {
                        title: "Member",
                        name: "Antonio Morello"
                    }
                ]
            }
        },
        computed: {
            endpoint() {
                return process.env.VUE_APP_ENDPOINT
            },
            lang() {
                return this.$store.state.currentLang
            },
            mainPeople() {
                var order = []
                order.push(this.personData.find(u =>
                    u.attributes.field_person_position == "Presidente" ||
                    u.attributes.field_person_position == "Chairman" ||
                    u.attributes.field_person_position == "President"))

                order.push(this.personData.find(u =>
                    u.attributes.field_person_position == "Vicepresidente" ||
                    u.attributes.field_person_position == "Vice presidente" ||
                    u.attributes.field_person_position == "Vicepresident" ||
                    u.attributes.field_person_position == "Vice-president" ||
                    u.attributes.field_person_position == "Vice president"))

                return order
            },
            consiglieri() {
                return this.personData.filter(p => {
                    return ![
                        "Presidente", "Chairman", "President",
                        "CEO", "Chief Executive Officer",
                        "Vicepresidente", "Vice presidente", "Vicepresident", "Vice-president",
                        "Vice president"
                    ].includes(p.attributes.field_person_position)
                }).sort((a, b) => {
                    return a.attributes.field_last_name > b.attributes.field_last_name ? 1 : -1
                })
            }
        },
        methods: {
            accordinText(index) {
                if (this.isOpen.includes(index)) {
                    return this.lang == 'it' ? "Chiudi" : "Close"
                } else {
                    return this.lang == 'it' ? "Leggi di più" : "Read more"
                }
            },
            openAccordion(index) {
                if (this.isOpen.includes(index)) {
                    this.isOpen = this.isOpen.filter(i => i !== index);
                } else {
                    this.isOpen.push(index);
                }
            },
            sourceProfilePhoto(url) {
                return process.env.VUE_APP_ENDPOINT + url
            },
            splittedTitle: function (value) {
                return value.replace(/(?![^<]*>)[^<]/g, c => `<span>${c}</span>\n`);
            },
        },
        watch: {
            lang() {
                getTranslation(this.$route.name, this.lang)
                    .then(res => {
                        this.$router.push({
                            path: res
                        })
                    })

                fetchSinglePage(this.currentPageId, this.lang)
                    .then(res => {
                        this.pageData = res[0]
                    })

                fetchNodes('person', {
                    include: ['field_person_photo']
                }, this.lang).then(res => {
                    this.personData = res

                })
            }
        },
        mounted() {
            fetchSinglePage(this.currentPageId, this.lang)
                .then(res => {
                    this.pageData = res[0]
                })

            fetchNodes('person', {
                    include: ['field_person_photo']
                }, this.lang)
                .then(res => {
                    this.personData = res
                    
                    console.log('this.personData',this.personData);

                })
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/colors";



a:hover{
  color:#e4d191;
  transition: .2s ease;
}

    .bold {
        font-weight: 600;
    }

    .bio {
        max-height: 168px;
        overflow: hidden;
        transition: 1s ease;

        &.open {
            max-height: 100%;
            transition: 1s ease;
        }
    }

    .people {
        margin-top: 20px;
        position: relative;
    }

    a {
        cursor: pointer;
        color: $mainColor;
        text-decoration: none;
        display: block;
        padding: 1rem 0rem;
        font-size: 1rem;
    }

    .person-2 {
        left: 33%;
    }


    .people-consiglieri {
        display: flex;
        padding-bottom: 3rem;

        &>div {
            width: 25%;
            display: flex;
            flex-direction: column;
        }

        .person {
            h2 {
                font-size: 2.5rem;
                line-height: 2.5rem;
                color: #D2BC75;
                margin: 1rem 0rem 2rem 0rem;
            }

            .role {
                position: relative;
                padding-left: 70px;

                &:before {
                    content: "";
                    width: 45px;
                    left: 0px;
                    height: 1px;
                    position: absolute;
                    background-color: $mainColor;
                    top: 13px;
                }


            }

        }

        .img {
            position: relative;
            overflow: hidden;
            width: 220px;
            height: 200px;
            justify-content: flex-start;
            margin-bottom: 10px;

            img {
                width: 100%;
                top: 0;
                height: auto;
            }
        }


    }

    .filigrana {
        position: absolute;
        right: -200px;
        top: -200px;
        z-index: -10;
    }

    .filigrana-2 {
        right: auto;
        top: auto;
        bottom: -500px;
        opacity: 0.051;
        left: -200px;
    }

    .people .person {
        display: flex;
        position: relative;
        max-width: 66%;
        margin-bottom: 15rem;

        .img {
            overflow: hidden;
            min-width: 300px;
            height: 425px;
            position: relative;
            transform: translateY(-10px);
            margin-right: 50px;

            &:before {
                background: #fff;
                position: absolute;
                transform: translate3d(-50%, -50%, 0) rotate(20deg);
                width: 154%;
                height: 191%;
                top: 50%;
                left: 50%;
                z-index: 1;
                content: "";
            }
        }

        img {
            width: 100%;
            height: auto;
            position: absolute;
            transform: scale3d(1.2, 1.2, 1);
        }

        h2 {
            color: $mainColor;
            font-size: 3.125rem;
            margin: 1rem 0rem 2rem 0rem;
            line-height: 3rem;
        }

        .role {
            position: relative;

            &:before {
                content: "";
                width: 120px;
                height: 1px;
                position: absolute;
                background-color: $mainColor;
                left: -140px;
                top: 13px;
            }
        }
    }




    .governance-bottom-text {
        background: #fafafa;
        padding: 2rem 1rem;

        /* > div > div {
            max-width: 992px;
        } */

        div>div:first-child {
            margin-top: 0;
            h3 {
                font-weight: 500;
                margin: 3rem 0rem;
                margin-bottom: 0;
            }
        }

        div>div {
            margin: 3rem 0rem ;
        }

        h3 {
            font-weight: 500;
            margin: 5rem 0rem 1rem 0rem;
            font-size: 28px;
        }

        p,
        p a,
        a {
            font-size: 14px;
            padding: 0;
        }

        ul {
            margin: 1rem 0rem 2rem 0rem;
            font-weight: 600;
            padding-left: 45px;

            li {
                //font-weight: 600;
                list-style: none;
                position: relative;
                margin: 0.6rem 0rem 1.2rem 0rem;
                padding-left: 8px;

text-align: left;
                /**/
                font-size: 24px;
                color: #d2bc75;
                font-family: "Cinzel";
                position: relative;

                &:before {
                content: "";
                background: #d2bc75;
                font-size: 25px;
                display: block;
                width: 40px;
                height: 1px;
                line-height: 7px;
                position: absolute;
                top: 13px;
                left: -43px;
                }


                span {

                        text-align: left;
                        font-family: "Poppins";
                        font-weight: 400;
                        color: #333333;
                        display: block;
                        font-size: 16px;
                        top: -10px;

                }
            }
        }
        .downloads {

            a {
                margin: 8px 0;
                display: block;
                span {
                    margin-left: 10px;
                }
            }
        }

        .data-cessazione {
            margin-top: 2rem;
        }
    }

    @media (min-width:992px) {
        .governance-bottom-text {
            h3 {
                font-size: 28px;
            }

            p,
            a,
            p a {
                font-size: 1rem;
            }

            p a {
                margin: 1rem 0rem;

                display: inline-block;
            }
        }
    }





    @media (max-width:1500px) {
        .people .person {
            max-width: 73%;
            margin-bottom: 9rem;
        }

        .person-2 {
            left: 30%;
        }
    }


    @media (max-width:1200px) {
        .people .person {
            max-width: none;
            margin-bottom: 6rem;
        }

        .person-2 {
            left: 0%;
        }

        .people-consiglieri {
            display: flex;
            justify-content: space-between;

            >div {
                width: 20%;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .people-consiglieri .person {
            .role {
                padding-left: 38px;

                &:before {
                    width: 28px;
                }
            }
        }

        .people-consiglieri .person h2 {
            font-size: 1.875rem;
            line-height: 1.875rem;
        }
    }

    @media (max-width:992px) {
        .governance-bottom-text ul li span {
            font-size: 14px;
        }
        .people-consiglieri {
            flex-direction: column;

            &>div {
                width: 100%;
                padding: 0;
                display: flex;
                margin-bottom: 2rem;
                flex-direction: row;

                .img {
                    margin: 0rem 2rem 0rem 0rem;
                    max-width: 223px;
                }

                div {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }
            }
        }

        .people .person .img img {
            width: 100%;
            height: auto;
        }
    }


    @media (max-width:768px) {
        .people .person {
            margin-bottom: 4rem;

            .img {
                min-width: 170px;
                height: 245px;
                margin-right: 30px;
            }

            h2 {
                font-size: 2.5rem;
                margin: 1rem 0rem 1rem 0rem;
            }
        }

        .people-consiglieri>div .img {
            width: 200px;
            min-width: 200px;
        }

        .people {
            .person {
                .role {
                    &:before {
                        width: 60px;
                        left: -70px;
                    }
                }
            }
        }


    }

    @media (max-width:576px) {
        .filigrana-2 {
            display: none;
        }

        .people .person .role:before {
            width: 60px;
            left: 4px;
        }

        .people .person .img {
            min-width: auto;
            height: 300px;
            width: 200px;
        }

        .people {
            margin-top: 70px;

            .person {
                flex-direction: column;

                br {
                    display: none;
                }

                .role {
                    padding-left: 5rem;
                }
            }
        }

        .people-consiglieri {
            >div {
                flex-direction: column;

                .img {
                    margin: 0rem 2rem 1rem 0rem;
                }
            }

            .person h2 {
                margin: 0rem 0rem 1rem 0rem;
            }
        }


    }
</style>