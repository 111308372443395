<template>
    <header class="header-intro">
        <img :src="bannerSrc" data-aos="reveal-image-scale" alt="">
        <h1 v-if="this.title" data-aos="main-title-reveal" v-html="splittedTitle(this.title)"></h1>
    </header>
</template>

<script>
    export default {
        name: 'header-intro',
        data: () => {
            return {

            }
        },
        props: {
            title: String,
            banner: String
        },
        computed: {
            bannerSrc() {
                return this.banner ? this.banner : '/img/placeholder.jpg'
            }
        },
        methods: {
            splittedTitle(value) {
                if(window.innerWidth>1024)
                    return value.replace(/(?![^<]*>)[^<]/g, c => `<span><br/>${c}</span>\n`);
                else
                    return value
            }
        },
        mounted() {
            //console.log(this.banner)
        }
    }
</script>

<style lang="scss" scoped>
    .header-intro {
        position: relative;
        overflow: hidden;
        z-index: -1;
        top: 3rem;
        min-height: 430px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            background: linear-gradient(#0000002e 10%, #00000082 150%);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
            content: "";
        }

        img {
            position: absolute;
            z-index: -1;
            width: 100%;
            height: auto;
            transform: scale3d(1.2, 1.2, 1);
        }

        h1 {
            margin-bottom: 70px;
            overflow: hidden;
            z-index: 1;
            padding: 0 20px;

            ::v-deep  span  {
                font-family: "Cinzel";
                position: relative;
                display: inline-block;
                margin: 0 -10px;
                z-index: 1;

                transform-style: preserve-3d;
                color: #fff;
                text-align: center;
                opacity: 0;
                min-width: 40px;
                transform: translateY(100px) rotateX(-51deg) rotateY(8deg) rotateZ(-1deg) scale3d(0.7, 0.7, 0);
            }
        }
    }

    @media (max-width:1200px) {
        .header-intro h1 {
            font-size: 4rem;
            margin-bottom: 20px;
        }
    }

    @media (max-width:768px) {
        .header-intro {
            min-height: 280px;
            h1 {
                font-size: 2.5rem;
            }
        }
    }

    @media (max-width:576px) {
        .header-intro h1 ::v-deep  span {
            min-width: 36px;
        }
        .header-intro {
            min-height: 240px;
            h1 {
                text-align: center;
                z-index: 9;
                font-size: 1.875rem;
            }
        }
    }
</style>