<template>
  <div class="template-page">
    <header-intro 
      v-if="pagedata && pagedata.attributes" 
      :title="pagedata.attributes.title" 
      :banner="this.bannerimg">
    </header-intro>
    <slot>
    </slot>
  </div>
</template>

<script>
import HeaderIntro from '../components/HeaderIntro.vue'
export default {
  name: 'basic-page',
  components: {
    HeaderIntro
  },
  data: () => {
    return {

    }
  },
  computed: {
  },
  props: {
    pagedata: Object,
    bannerimg: String
  },
  mounted() {
    //console.log(this.$route)
    localStorage.setItem('LS_ROUTE_KEY', this.$route.path)
    //console.log(localStorage.getItem('LS_ROUTE_KEY'))

    //console.log(this.$store.state.currentLang, this.$store.state.defaultLang)
    if(this.$route.fullPath.substring(1, 4)!=this.$store.state.currentLang+'/') {
      if(this.$route.fullPath[3]=='/')
        this.$store.commit('SET_CURRENT_LANG', this.$route.fullPath.substring(1, 3))
      else this.$store.commit('SET_CURRENT_LANG', this.$store.state.defaultLang)
      
    }

  }
}
</script>

<style lang="scss">
</style>
